import { Loader, Modal, useConfetti } from "@withjuly/solis";
import { useSearchParams } from "next/navigation";
import { useRouter } from "next/router";
import React, { ReactElement, useEffect, useState } from "react";
import { CreatorSettingsModal } from "~/components/CreatorSettings/CreatorSettingsModal";
import { CreatorNavigation } from "~/components/Navigation/Creator/CreatorNavigation";
import { WelcomeModal } from "~/components/ProductTour/WelcomeModal";
import AppReroute from "~/utils/AppReroute";
import { useAuth } from "~/utils/context/auth";
import { PitchQueueProvider } from "~/utils/context/pitch-queue";

interface CreatorLayoutProps {
	children: ReactElement;
}

export const CreatorLayout: React.FC<CreatorLayoutProps> = (props) => {
	return (
		<AppReroute allowedAccountTypes={["creator"]}>
			<PitchQueueProvider>
				<CreatorLayoutInternal {...props} />
			</PitchQueueProvider>
		</AppReroute>
	);
};

export const CreatorLayoutInternal: React.FC<CreatorLayoutProps> = ({
	children,
}) => {
	const router = useRouter();
	const confetti = useConfetti();
	const userContext = useAuth();
	const params = useSearchParams();

	const [isWelcomeModalOpen, setIsWelcomeModalOpen] = useState<boolean>(false);
	const [hasShownConfetti, setHasShownConfetti] = useState<boolean>(false);
	const [isSettingsModalOpen, setIsSettingsModalOpen] =
		useState<boolean>(false);

	useEffect(() => {
		if (params.has("settings")) {
			setIsSettingsModalOpen(true);
		}
	}, [params]);

	const closeWelcomeAndOpenTooltip = () => {
		setIsWelcomeModalOpen(false);
		// eslint-disable-next-line unused-imports/no-unused-vars
		const { onboarded, ...routerQuery } = router.query;
		router.replace({
			query: { ...routerQuery },
		});
	};

	useEffect(() => {
		if (router.isReady && router.query?.onboarded === "true")
			setIsWelcomeModalOpen(true);
	}, [router]);

	useEffect(() => {
		if (
			router.isReady &&
			router.query?.onboarded === "true" &&
			!hasShownConfetti
		) {
			confetti();
			setHasShownConfetti(true);
		}
	}, [router, confetti, hasShownConfetti]);

	if (userContext.isLoading) {
		return (
			<div className="flex h-screen w-screen items-center justify-center">
				<Loader />
			</div>
		);
	}

	return (
		<div className="flex h-screen flex-col">
			<a className="sr-only bg-gray-900" href="#main">
				Skip to content
			</a>
			<nav className="w-full border-b border-gray-500">
				<CreatorNavigation
					onOpenSettings={() => setIsSettingsModalOpen(true)}
				/>
			</nav>

			<main className="flex grow flex-col" id="main">
				<div className="h-full bg-gray-900">{children}</div>
			</main>

			{userContext.user?.creatorProfile && (
				<CreatorSettingsModal
					isOpen={isSettingsModalOpen}
					setIsOpen={setIsSettingsModalOpen}
					creatorProfile={userContext.user?.creatorProfile}
				/>
			)}

			<Modal
				isOpen={isWelcomeModalOpen}
				setIsOpen={closeWelcomeAndOpenTooltip}
				content={<WelcomeModal setIsOpen={closeWelcomeAndOpenTooltip} />}
			/>
		</div>
	);
};
